import auth0, { WebAuth, Auth0DecodedHash } from 'auth0-js';
import axios from 'axios';

class Auth {
  auth0: WebAuth;

  idToken?: string;

  profile?: any;

  expiresAt?: number;

  constructor() {
    this.auth0 = new auth0.WebAuth({
      // the following three lines MUST be updated
      domain: process.env.REACT_APP_AUTH_CONFIG_DOMAIN,
      audience: `https://${process.env.REACT_APP_AUTH_CONFIG_DOMAIN}/userinfo`,
      clientID: process.env.REACT_APP_AUTH_CONFIG_CLIENT_ID,
      redirectUri: `${process.env.REACT_APP_APP_URI}/callback`,
      responseType: 'id_token',
      scope: 'openid profile',
    });

    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
    this.isTokenValid = this.isTokenValid.bind(this);
  }

  getProfile() {
    return this.profile;
  }

  getIdToken() {
    return this.idToken;
  }

  isAuthenticated() {
    if (!this.expiresAt) return false;

    return new Date().getTime() < this.expiresAt;
  }

  signIn() {
    this.auth0.authorize({
      // @ts-ignore
      form_mode: 'login',
      partners_client_url: process.env.REACT_APP_PARTNERS_CLIENT_URL,
      partners_api_url: process.env.REACT_APP_PARTNERS_API_URL,
    });
  }

  isTokenValid() {
    return axios
      .get(`${process.env.REACT_APP_API_URI}`, {
        headers: { Authorization: `Bearer ${this.getIdToken()}` },
      })
      .then(() => {
        console.log('user is an administrator'); // eslint-disable-line no-console
      })
      .catch(err => {
        console.log('user is not an administrator', err); // eslint-disable-line no-console
        this.signOut();
      });
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.setSession(authResult);
        resolve();
      });
    });
  }

  setSession(authResult: Auth0DecodedHash) {
    this.idToken = authResult.idToken;
    this.profile = authResult.idTokenPayload;
    // set the time that the id token will expire at
    this.expiresAt = authResult.idTokenPayload.exp * 1000;
    this.isTokenValid();
  }

  signOut() {
    this.auth0.logout({
      returnTo: process.env.REACT_APP_APP_URI,
      clientID: process.env.REACT_APP_AUTH_CONFIG_CLIENT_ID,
    });
  }

  silentAuth() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) return reject(err);
        this.setSession(authResult);
        resolve();
      });
    });
  }
}

const auth0Client = new Auth();

export default auth0Client;
