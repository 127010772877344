import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import auth0Client from '../Auth';

interface Props extends Omit<RouteProps, 'component'> {
  component: React.ElementType;
  checkingSession: boolean;
}

const SecuredRoute: React.FC<Props> = ({ component: Component, path, checkingSession, exact }) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={routeProps => {
        if (checkingSession) return <div>Validating session...</div>;

        if (!auth0Client.isAuthenticated()) {
          auth0Client.signIn();
          return <div />;
        }

        return <Component {...routeProps} />;
      }}
    />
  );
};

export default SecuredRoute;
