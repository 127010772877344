import React, { Component, lazy, Suspense } from 'react';
import { Route, withRouter, Switch, RouteComponentProps } from 'react-router-dom';
import { Provider, createClient, dedupExchange, cacheExchange, fetchExchange } from 'urql';
import SecuredRoute from './securedRoute/SecuredRoute';
import auth0Client from './Auth';
import { ModalProvider } from 'react-modal-hook';

const Sidebar = lazy(() => import('./sidebar/Sidebar'));

const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Utilities = lazy(() => import('./utilities/Utilities'));

const Organisations = lazy(() => import('./organisations/Organisations'));

const OrganisationDetails = lazy(() => import('./organisations/OrganisationDetails'));

const UserDetails = lazy(() => import('./users/UserDetails'));

const Users = lazy(() => import('./users/Users'));

const BillingCustomers = lazy(() => import('./billing/customers/Customers'));

const BillingCustomerCreate = lazy(() => import('./billing/customers/CustomerCreate'));

const BillingProducts = lazy(() => import('./billing/products/Products'));

const BillingProduct = lazy(() => import('./billing/products/Product'));

const AwarenessDays = lazy(() => import('./awarenessDays/AwarenessDays'));

const BridgeMessages = lazy(() => import('./bridgeMessages/BridgeMessages'));

const BridgeEvents = lazy(() => import('./bridgeEvents/BridgeEvents'));

const Callback = lazy(() => import('./callback/Callback'));

const LoadingMessage = () => <>Loading...</>;

type PropTypes = RouteComponentProps;

class App extends Component<PropTypes> {
  state = {
    checkingSession: true,
  };

  async componentDidMount() {
    if (this.props.location.pathname === '/callback') {
      this.setState({ checkingSession: false });
      return;
    }

    try {
      await auth0Client.silentAuth();
      this.forceUpdate();
    } catch (err) {
      if (err.error !== 'login_required') console.error(err.error);
    }

    this.setState({ checkingSession: false });
  }

  render() {
    let client;

    if (auth0Client.isAuthenticated()) {
      client = createClient({
        url: `${process.env.REACT_APP_API_URI}/graphql`,
        fetchOptions: () => ({
          headers: { Authorization: `Bearer ${auth0Client.getIdToken()}` },
        }),
        exchanges: [dedupExchange, cacheExchange, fetchExchange],
      });
    }

    return (
      // @ts-ignore
      <Provider value={client}>
        <ModalProvider>
          <div className="container-fluid">
            <div className="row">
              {auth0Client.isAuthenticated() && !this.state.checkingSession && (
                <Suspense fallback={<LoadingMessage />}>
                  <Sidebar />
                </Suspense>
              )}
              <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                <Suspense fallback={<LoadingMessage />}>
                  <Switch>
                    <Route exact path="/callback">
                      <Callback />
                    </Route>
                    <SecuredRoute exact path="/" component={Dashboard} checkingSession={this.state.checkingSession} />
                    <SecuredRoute
                      path="/utilities"
                      component={Utilities}
                      checkingSession={this.state.checkingSession}
                    />
                    <SecuredRoute
                      path="/organisations/:organisationId"
                      component={OrganisationDetails}
                      checkingSession={this.state.checkingSession}
                    />
                    <SecuredRoute
                      path="/users/:userId"
                      component={UserDetails}
                      checkingSession={this.state.checkingSession}
                    />
                    <SecuredRoute path="/users" component={Users} checkingSession={this.state.checkingSession} />
                    <SecuredRoute
                      path="/organisations"
                      component={Organisations}
                      checkingSession={this.state.checkingSession}
                    />
                    <SecuredRoute
                      path="/billing/customers/create/:organisationId"
                      component={BillingCustomerCreate}
                      checkingSession={this.state.checkingSession}
                    />
                    <SecuredRoute
                      path="/billing/customers"
                      component={BillingCustomers}
                      checkingSession={this.state.checkingSession}
                    />
                    <SecuredRoute
                      path="/billing/products/:productId"
                      component={BillingProduct}
                      checkingSession={this.state.checkingSession}
                    />
                    <SecuredRoute
                      path="/billing/products"
                      component={BillingProducts}
                      checkingSession={this.state.checkingSession}
                    />
                    <SecuredRoute
                      path="/awareness-days"
                      component={AwarenessDays}
                      checkingSession={this.state.checkingSession}
                    />
                    <SecuredRoute
                      path="/bridge-updates"
                      component={BridgeMessages}
                      checkingSession={this.state.checkingSession}
                    />
                    <SecuredRoute
                      path="/bridge-events"
                      component={BridgeEvents}
                      checkingSession={this.state.checkingSession}
                    />
                  </Switch>
                </Suspense>
              </main>
            </div>
          </div>
        </ModalProvider>
      </Provider>
    );
  }
}

export default withRouter(App);
